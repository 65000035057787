<div class="datatable-card">
  <!-- actions and forms -->
  <div class="data-table-actions m-0 p-0 d-flex align-items-center">
    <ng-content></ng-content>
  </div>
  <ng-container>
    <ag-grid-angular
      (firstDataRendered)="onFirstDataRendered()"
      (gridReady)="onGridReady($event)"
      (rowClicked)="onRowDoubleClicked.emit($event)"
      [animateRows]="true"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions"
      [paginationAutoPageSize]="false"
      [pagination]="false"
      [rowData]="rowData"
      [style]="style"
      class="ag-theme-alpine"
      headerHeight="52"
      id="simpleDataTable"
      rowHeight="52"
      rowSelection="single"
    ></ag-grid-angular>
  </ng-container>
</div>
