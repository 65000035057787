import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, ViewChild,} from '@angular/core';
import {AgGridAngular, AgGridModule} from 'ag-grid-angular';
import {ColDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent,} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CustomLoadingOverlayComponent} from '@shared/components/ups-datatable/components/custom-loading-overlay.component';
import {CustomNoRowsOverlayComponent} from '@shared/components/ups-datatable/components/custom-no-rows-overlay.component';

@Component({
  standalone: true,
  selector: 'cab-simple-datatable',
  templateUrl: './simple-datatable.component.html',
  styleUrls: ['./simple-datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AgGridModule, NgbModule],
})
export class SimpleDatatableComponent {
  @Input({required: true}) rowData: any[];
  @Input({required: true}) columnDefs: ColDef[];
  @Input() isFullWidth: boolean;
  @Input() style = 'width: 100%; height: calc(90vh - 350px); color: #4B5675';
  @Output() onRowDoubleClicked = new EventEmitter<RowDoubleClickedEvent>();
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: false,
  };
  public gridOptions = {
    loadingOverlayComponent: CustomLoadingOverlayComponent,
    noRowsOverlayComponent: CustomNoRowsOverlayComponent,
  };
  private _translate = inject(TranslateService);
  // private _defaultService = inject(DefaultService);
  private gridApi!: GridApi;
  private gridColumnApi!: ColumnApi;

  translateColumnHeaders(colDefs: ColDef[]): void {
    if (colDefs) {
      colDefs.forEach((colDef: any) => {
        if (colDef.headerName) {
          colDef.headerName = this._translate.instant(colDef.headerName);
        }
        this.translateColumnHeaders(colDef.children);
      });
    }
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridColumnApi = params!.columnApi;
    this.gridApi = params.api;
    this.translateColumnHeaders(this.columnDefs);
    this.gridApi.setColumnDefs(this.columnDefs);
  }

  onFirstDataRendered() {
    if (
      (this.columnDefs.length < 8 && window.innerWidth > 1280) ||
      this.isFullWidth
    ) {
      const colsLength = this.gridColumnApi.getAllGridColumns().length;
      const actionsLabel = ['Harakat', 'Ҳаракат', 'Движение', 'Action'];
      const actionsCol = this.gridColumnApi.getAllGridColumns()[colsLength - 1];
      const actionsColHeader = actionsCol.getUserProvidedColDef()?.headerName;

      this.gridApi.sizeColumnsToFit({
        columnLimits: [
          {key: this.gridColumnApi.getAllGridColumns()[0], maxWidth: 100},
          {key: 'id', maxWidth: 160},
          {
            key: actionsLabel.includes(actionsColHeader!) ? actionsCol : '',
            maxWidth: 100,
          },
        ],
      });
    } else {
      this.autoSizeAll(true);
    }
  }

  // column resize function
  autoSizeAll(skipHeader: boolean) {
    const allColumnIds: string[] = [];
    this.gridColumnApi.getColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });

    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }
}
