import {AgGridAngular} from 'ag-grid-angular';
import {Component, inject, OnInit, signal} from '@angular/core';
import {CabSelectComponent} from '@shared/components/forms/components/cab-select/cab-select.component';
import {ReferenceBranchesService} from '@control-panel/components/unused-pages/reference/services/reference-branches.service';
import {SimpleDatatableComponent} from '@shared/components/simple-datatable/simple-datatable.component';
import {ModalService} from '@core/services/modal.service';
import {AllowedCoatoListService} from '../services/allowed-coato-list.service';
import {Router} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {RegionsOperatorService} from '@app/common/coato-selector/services/regions-operator.service';
import {ROLES} from '@core/enums/Roles.enum';
import {FormsModule} from '@angular/forms';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {CoatoCodeService} from '../services/coato.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {LiveCallsDataService} from '@app/common/customer-evaluation/live-calls-data.service';

@Component({
  selector: 'app-coato-selector-modal',
  templateUrl: './coato-selector-modal.component.html',
  styleUrl: './coato-selector-modal.component.scss',
  standalone: true,
  imports: [
    CabSelectComponent,
    AgGridAngular,
    SimpleDatatableComponent,
    TranslateModule,
    FormsModule,
    NgSelectModule
  ],
  providers: []
})
export class CoatoSelectorModalComponent implements OnInit {
  _coatoBranches = inject(ReferenceBranchesService);
  _modalService = inject(ModalService);
  _coatoRegionsDropdownService = inject(RegionsOperatorService);
  _allowedCoatoListService = inject(AllowedCoatoListService);
  _coatoCodeService = inject(CoatoCodeService);
  translateService = inject(TranslateService);
  resData: any;
  regions: any;
  isLoading = false;
  currentUser: any;
  colDefs = [
    {
      field: 'code',
      headerName: this.translateService.instant('Components.Spravochniks.SpravochniksBranches.TableHeader.Code'),
      width: 100
    },
    {
      field: 'name',
      headerName: this.translateService.instant('Components.Spravochniks.SpravochniksBranches.TableHeader.Name'),
      flex: 1
    }
  ];
  rowData = signal([]);
  private _liveCallsDataService = inject(LiveCallsDataService);
  private zeroOption = {
    id: '1',
    name: this.translateService.instant('REN'), code: '00000',
  };

  private router = inject(Router);

  constructor(private _authenticationService: AuthService) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  ngOnInit() {
    this.fetchRegionsDropdownDetails();
  }

  fetchRegionsDropdownDetails() {
    this.isLoading = true;
    this._coatoRegionsDropdownService.getAll({parent_code: '00000', per_page: 200}).subscribe({
      next: (value) => {
        const role = this.currentUser?.roles[0]?.name;

        if (role === ROLES.ADMIN || role === ROLES.DEPARTMENT_HEAD) {
          this.regions = [this.zeroOption, ...value?.data?.data];
        } else {
          this.regions = [...value?.data?.data];
        }

        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    });
  }

  fetchAllowedCoatoList(code) {
    if (code == '00000') {

      const inZeroOptionSelected = {data: this.zeroOption};
      this.coatoSelected(inZeroOptionSelected);
      this.isLoading = false;
    } else if (!code) {
      this.rowData.set([]);
    } else {
      this._allowedCoatoListService.getAll({parent_code: code, per_page: 50}).subscribe({
        next: (value) => {
          const region = this.regions.find(region => region.code == code);
          this.rowData.set([{
            code: code,
            parent_code: code,
            name: region.name,
          }].concat(value?.data?.data));
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          console.log(err);
        }
      });
    }
  }

  onChange($event: any) {
    let parentCode = $event?.code;

    this.fetchAllowedCoatoList(parentCode);
    // with this i filter users in Region level
  }

  coatoSelected(event: any) {
    this._coatoCodeService.setCoatoCode(event?.data?.code);
    this._liveCallsDataService.sendCoatodata(event?.data);
    this._coatoCodeService.setCoatoName(event?.data?.name);
    this._coatoCodeService.setParentCode(event?.data?.parent_code);
    this._coatoBranches.sendCoatoData(event?.data);
    this.refreshPage();
    this._modalService.closeAll();
  }

  refreshPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([this.router.url], {skipLocationChange: true});
    this._coatoCodeService.isCoatoChange.set(true);
  }
}
