<div class="modal-body">
  <div class="coato-selector-modal">
    @if (isLoading) {
      <div class="loader-block">
        <div class="loaderTicket"></div>
      </div>
    } @else {
      <div class="d-flex">
        <!-- <app-cab-select
          [placeholder]="'SelectRegion' | translate"
          [dataKey]="'code'"
          [optionValue]="'code'"
          (selectChange)="onChange($event)"
          [clearable]="false"
          [options]="regions"
          class="w-2/3"
        ></app-cab-select> -->
        <ng-select
          class="w-full mb-1"
          [items]="regions"
          bindLabel="name"
          [placeholder]="'SelectRegion' | translate"
          bindValue="code"
          (change)="onChange($event)"
          [clearable]="false"
        ></ng-select>
      </div>
      <div>
        <cab-simple-datatable
          (onRowDoubleClicked)="coatoSelected($event)"
          [columnDefs]="colDefs"
          [rowData]="rowData()"
        />
      </div>
    }
  </div>
</div>
